body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .logo{
    cursor: pointer;
  }

  .navbar {
    height: 60px;
    background-color: #00000095;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-elements {
    margin: auto;
  }
  
  .nav-elements ul {
    display: flex;
    list-style-type: none;
    padding: 0;
  }
  
  .nav-elements ul a {
    font-family: 'Nunito Sans';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    margin: .6rem;
  }
  
  .nav-elements ul a:hover {
    transition: all .5s ease-in-out;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }

  .nav-elements ul a.active {
    color: #000000dc;
    font-weight: 500;
    position: relative;
    padding: 7px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: white;
    transition: .5s ease-in-out;
    border-radius: 5px;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.663);
  }
  
  .nav-elements ul a.active::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #839795dc;
  }

  @media (max-width: 768px) {
    .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .nav-elements {
      position: absolute;
      right: 0;
      top: 60px;
      background-color: #444342;
      width: 0px;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .nav-elements.active {
      width: 270px;
    }
  
    .nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      padding: 2rem;
      border-bottom: 1px solid white;
    }
  }