.accueil{
    width: 100%;
    height: 100%;
}

.accueil-background{
    width: 100%;
    height: 100vh;
    position: relative;
}

.accueil-background::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0.5;
}

.accueil-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
}

.accueil-content{
    width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.accueil-title{
    font-family: 'Fredericka the Great', serif;
    font-size: 84px;
    text-shadow: 4px 4px 2px #b1b1b1;
    margin: 0;
    text-transform: uppercase;
}

.accuei-subtitle{
    text-decoration: overline;
    font-family: 'Nunito Sans';
    font-size: 48px;
    text-shadow: 4px 4px 2px #b1b1b1;
    margin: .5rem 0 ;
    text-transform: uppercase;
    color: #000000;
}

.accueil-group{
    margin: 2rem 0;
}

.accueil-listes{
    display: flex;
    justify-content: center;
    padding: 0;
}

.accueil-list{
    list-style: none;
    padding: 10px;
    font-size: 18px;
}

.accueil-logo{
    width: 40px;
    height: 40px;
}

.accueil-logo:hover{
    transform: scale(1.2);
    transition-duration: .5s;
}

@media (max-width: 768px){
    .accueil-title{
        font-size: 72px;
    }    

    .accuei-subtitle{
        font-size: 36px;
    }
}

@media (max-width: 425px){
    .accueil-title{
        font-size: 60px;
    }    

    .accuei-subtitle{
        font-size: 30px;
    }

    .accueil-logo {
        width: 40px;
        height: 40px;
    }
}