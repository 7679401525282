.modal-element{
  width: 50%;
  cursor: pointer;
}

.modal-element-image{
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal-content {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.close {
  position: absolute;
  top: 60px;
  right: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
}

.modal-image {
  width: 80%;
  height: 80%;
  margin: 5rem 0;
}

@media (max-width: 900px) {
  .modal-element{
    width: 100%;
  }
}