.contact{
    width: 100%;
    background-image: url(../../icons/fondLogo.png);
    background-size: cover;
}

.contact-component{
    width: 100%;
    padding-bottom: 2rem;
    background-color: #ffffffbc;
}

.contact-title{
    font-family: "Chau Philomene One", serif;
    text-transform: uppercase;
    text-align: center;
    font-size: 48px;
    margin: 0;
    padding: 3rem 0 2rem;
}

.contact-name{
    font-family: 'Fredericka the Great', serif;
    font-size: 40px;
    text-align: center;
    margin: 1rem 0;
}

.contact-lists{
    padding: 0;
    margin: 0 1rem;
}

.contact-list{
    display: flex;
    justify-content: center;
    list-style-type: none;
    font-family: 'Oswald', sans-serif;
}

.contact-list-icon{
    margin: auto 0;
    padding: 0 1rem 0 0;
    width: 40px;
    height: 40px;
}

.contact-list-text{
    font-size: 18px;
    font-family: 'Georgia', sans-serif;
    font-weight: bold;
}

.contact-logo-lists{
    display: flex;
    justify-content: center;
    padding: 0;
}

.contact-logo-list{
    list-style-type: none;
    margin: 0;
}

.contact-logo{
    padding: 0 .5rem;
    width: 40px;
    height: 40px;
}

.contact-logo:hover{
    transform: scale(1.2);
    transition-duration: .5s;
}

@media (max-width: 768px){
    .contact-title{
        font-size: 36px;
    }

    .contact-name{
        font-size: 30px;
    }

    .contact-list-text{
        font-size: 16px;
    }
}

@media (max-width: 425px){
    .contact-title{
        font-size: 30px;
    }
}