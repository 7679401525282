.card{
    width: 260px;
    height: 425px;
    text-align: center;
    margin: 1rem 1.5rem;
    background:#94bfc7;
    box-shadow: 0px 2px 7px 1px;;
    cursor: pointer;
    border-radius: 1rem;
}

.card:hover{
    scale: 1.05;
    transition: all .8s ease-in-out;
}

.card-img{
    width: 100%;
    height: 15rem;
    border-radius: 1rem 1rem 0 0;
}

.card-title{
    font-family: 'Chau Philomene One';
    text-transform: uppercase;
    font-size: 24px;
    margin: 1.5rem;
    color: #fff;
}

.card-btn{
    background-color: #ffffff;
    border: #000000 1px solid;
    font-size: 16px;
    font-family: 'Georgia', sans-serif;
    font-weight: bold;
    padding: .5rem .8rem; 
    margin: .5rem;
    border-radius: 10rem;
    color: #000000;
    cursor: pointer;
}

.card-btn:hover{
    background-color: #000000;
    color: #ffffff;
    transition-duration: 0.3s;
}

.realisations{
    width: 100%;
    background-color: #f8f8f8;
}

.realisations-title{
    font-family: "Chau Philomene One", serif;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 4.5rem 0 1rem
}

.realisations-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.realisations-card-selected{
    text-align: center;
    background-color: #94bfc7;
    display: flex;
}

.realisations-card-selected-element{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 2rem;
}

.realisations-card-selected-title{
    font-family: 'Chau Philomene One';
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
}

.realisations-card-selected-text{
    font-size: 18px;
    font-family: 'Georgia', sans-serif;
    color: #fff;
    margin: 1.5rem 0 2rem;
}

.realisations-card-selected-picture{
    width: 45%;
    padding: 1rem 0;
}

.realisations-element{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.realisations-map{
    margin: auto;
    width: 100%;
}

.leaflet-container{
    width: 100%;
    height: 60vh;
    z-index: 0;
    max-height: 50vh;
}

@media (max-width: 1060px){
    .realisations-map{
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 900px){
    .realisations-card-selected{
        flex-direction: column;
        align-items: center;
    }

    .realisations-card-selected-element {
        width: 85%;
    }

    .realisations-card-selected-picture{
        width: 70%;
    }
}

@media (max-width: 768px){
    .realisations-title{
        font-size: 36px;
    }
    
    .realisations-card-selected-title{
        font-size: 24px;
    }

    .realisations-card-selected-text{
        font-size: 16px;
    }
}

@media (max-width: 425px){
    .realisations-title{
        font-size: 30px;
    }

    .realisations-card-selected-picture{
        width: 80%;
    }

    .realisations-card-selected-title{
        font-size: 18px;
    }
}