.talent{
    width: 100%;
    height: 100%;
    background-color:#94bfc7;
}

.talent-element{
    display: flex;
    flex-direction: column;
}

.talent-background{
    background-image: url(../../icons/avantprojet-min.jpg);
    background-size: cover;
    background-position: center;
}

.talent-fond{
    background: rgb(255 255 255 / 50%);
    margin: 2rem;
}

.talent-title{
    font-family: 'Chau Philomene One', serif;
    text-transform: uppercase;
    margin: 0 0 1rem 0;
    font-size: 48px;
    color: #000000;
    text-align: center;
    padding: 3.5rem 0 0;
}

.talent-text{
    font-size: 22px;
    font-family: Georgia;
    color: #000000;
    text-align: center;
    padding: 0 3rem 1.5rem;
    margin: 0 10%;
}

.talent-text-dossier{
    margin: auto;
    text-align: center;
    color: #000;
    padding: 1rem;
    font-size: 22px;
    font-family: "Georgia", sans-serif;
    background-color: #afcfbd;
    text-transform: uppercase;
}

.talent-lists{
    list-style-type: none;
    padding: 0;
    margin: -6px 0;
}

.talent-list{
    margin: 0;
    background: #94bfc7;
}

.talent-list:nth-child(even){
    background: #afcfbd;
}

.talent-subtitle{
    font-family: 'Dawning of a New Day', serif;
    margin: 0 20%;
    font-size: 40px;
    color: #000000;
    line-height: 2.5rem;
}

.talent-list-group{
    padding: 1rem 0; 
}

.talent-list-text{
    color: #fff;
    margin: .8rem 20%;
    font-size: 18px;
    font-family: 'Georgia', sans-serif;
}

.talent-link{
    color: #000000;
    font-style: italic;
}

.talent-img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
}
@media (max-width: 1024px){
    .talent-text{
        padding: 0 .5rem 1.5rem;
    }
}

@media (max-width: 768px){
    .talent-title{
        font-size: 36px;
    }

    .talent-subtitle{
        font-size: 34px;
    }
    
    .talent-text{
        font-size: 18px;
    }

    .talent-list-text{
        font-size: 16px;
    }

    .talent-text-dossier{
        font-size: 18px;
    }
}

@media (max-width: 425px){
    .talent-list-text{
        margin: .8rem 1.5em;
    }

    .talent-title{
        font-size: 30px;
    }

    .talent-subtitle{
        font-size: 28px;
        margin: 0 2rem;
        line-height: 2rem;
    }
    
    .talent-text{
        font-size: 16px;
    }

    .talent-text-dossier{
        font-size: 16px;
    }

    .talent-img{
        width: 100%;
        height: 30vh;
    }
}