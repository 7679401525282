.reglementations{
    background-color:#94bfc7;
    width: 100%;
    height: 100%;
}

.reglementations-element{
    display: flex;
    justify-content: center;
}

.reglementations-component{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.reglementations-title{
    font-family: "Chau Philomene One", serif;
    font-size: 48px;
    text-transform: uppercase;
    color: #000000;
    padding: 2.5rem 0 0;
    margin-bottom: 0;
}

.reglementations-lists{
    padding: 0;
}

.reglementations-list{
    
    list-style: none;
    color: #fff;
}

.reglementations-list-title{
    font-size: 40px;
    font-family: 'Dawning of a New Day', sans-serif;
    margin: 1rem 0;
    color: #000000;
    line-height: 2.5rem;
}

.reglementations-list-text{
    font-size: 18px;
    font-family: 'Georgia', sans-serif;
}

.reglementations-link{
    color: #000000;
    font-style: italic;
}

.reglementations-list-img{
    width: 100%;
    height: 100%;
}

@media (min-width: 1440px){
    .reglementations-list-img{
        max-width: 60em;
        display: flex;
        margin: auto;
    }
}

@media (max-width: 1024px){

    .reglementations-element{
        flex-direction: column;
        align-items: center;
    } 

    .reglementations-component{
        width: 80%;
    }
}

@media (max-width: 768px){
    
    .reglementations-title{
        font-size: 36px;
    }

    .reglementations-list-title{
        font-size: 30px;
    }
    
    .reglementations-list-text{
        font-size: 16px;
    }
}
    
@media (max-width: 425px){

    .reglementations-component{
        width: 85%;
        padding: 0;
    }

    .reglementations-title{
        font-size: 30px;
    }
    
    .reglementations-list-text{
        font-size: 16px;
    }

    .reglementations-list-title{
        line-height: 2rem;
    }

}