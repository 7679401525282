.about{
    width: 100%;
    height: 100%;
    padding-bottom: 1.5rem;
}

.about-element{
    display: flex;
    justify-content: center;
}

.about-component{
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 2rem 1rem;
}

.about-title{
    font-family: 'Chau Philomene One', serif;
    text-transform: uppercase;
    margin: 1rem 0 1rem 0;
    font-size: 48px;
}

.about-text{
    font-size: 18px;
    font-family: Georgia;
    margin: .8rem 0 0;
}

.about-img{
    width: 55%;
    max-width: 800px;
}

@media (max-width: 1024px){
    .about-element{
        flex-direction: column;
        align-items: center;
    }

    .about-component{
        width: 70%;
    }

    .about-img{
        width: 80%;
        max-width: 600px;
    }
}

@media (max-width: 768px){
    .about-title{
        font-size: 36px;
    }

    .about-text{
        font-size: 16px;
    }

    .about-img{
        max-width: 600px;
        max-height: 600px;
        width: 100%;
    }
}

@media (max-width: 425px){
    .about-component{
        width: 80%;
    }

    .about-title{
        font-size: 30px;
    }

    .about-img{
        max-width: 450px;
        max-height: 450px;
        width: 100%;
        height: 100%;
    }
}