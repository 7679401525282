.footer{
    background-color: rgba(0, 0, 0, 0.84);
}

.footer-lists{
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 1rem 0 0;
}

.footer-list{
    padding: .5rem 1rem;
}

.footer-list a{
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    font-family: 'Nunito Sans';
    text-transform: uppercase;
}

.footer-list a:hover{
    text-decoration: underline;
}

.footer-copyright{
    margin: 0;
    font-size: 12px;
    color: #fff;
    text-align: center;
    font-family: 'Nunito Sans';
    padding: 0.5rem 0 1rem 0;
}

.footer-logo{
    text-align: center;
}

.footer-img{
    width: 100px;
    text-align: center;
    height: 80px;
}